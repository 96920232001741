/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { ActionIcon, Badge, Button, CloseButton, Group, SimpleGrid, ThemeIcon, Tooltip } from '@mantine/core'
import { IconFilter, IconSettingsAutomation, IconSettingsOff } from '@tabler/icons-react'
import _ from 'lodash'
import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { FilterMultiSelect } from './FilterMultiSelect'
import { FilterSelect } from './FilterSelect'
import { TableFiltersContext } from '../TableContexts';
import { FilterTextInput } from './FilterTextInput';
import { FilterNumberInput } from './FilterNumberInput';
import { FilterPhoneNumberInput, renderSelectedNumber } from './FilterPhoneNumberInput';
import { FilterDateRangeInput } from './FilterDateRangeInput';
import { NewDropdown } from '../../NewDropdown'
import { FilterCheckbox } from './FilterCheckbox';

export default function FilterControl ({ filters, selected, onChange, querying }) {
  const updateFilter = useCallback((id, value) => {
    const filter = _.find(filters, { id })
    console.debug('Updating filter in control.', id, value, filter, filter?.resets, filters)

    if ('onUpdate' in filter) {
      filter.onUpdate(value)
    }

    const filterChanges = {}
    if ('resets' in filter) {
      filter.resets.forEach(reset => { filterChanges[reset] = null })
    }

    filterChanges[filter.id] = value
    onChange(filterChanges)
  }, [filters, onChange])

  useEffect(() => {
    const hiddenFilters = filters.filter(f => f.hidden)
    if (hiddenFilters.length > 0) {
      const selectedKeys = Object.keys(selected)
      const filterChanges = {}
      for (const filter of hiddenFilters) {
        if (selectedKeys.includes(filter.id)) {
          filterChanges[filter.id] = null
        }
      }
      onChange(filterChanges)
    }
  }, [filters])

  const removeFilterValue = (id, value) => {
    const selectedArray = selected[id].constructor === Array ? selected[id] : [selected[id]]
    if (!selectedArray.includes(value)) return
    if (selectedArray?.length > 1) {
      updateFilter(id, selected[id].filter(item => item !== value))
    } else {
      updateFilter(id, null)
    }
  }

  useEffect(() => {
    for (const filter of filters) {
      if (selected[filter] !== undefined) {
        if ('onUpdate' in _.find(filters, { id: filter })) {
          // Run the filter's onUpdate function when page loads, if it exists
          _.find(filters, { id: filter }).onUpdate(selected[filter])
        }
      }
    }
  }, [])

  const isActive = useMemo(() => Object.keys(selected).some(s => [...filters].map(f => f.id).includes(s)), [selected, filters])

  const FilterButton = useMemo(() => <Button variant='light' color={isActive ? 'blue' : 'gray'} disabled={querying} leftSection={<IconFilter />}>Filters</Button>, [isActive, querying])

  console.debug('Filter control updating', { filters, selected })
  return (
    <Group wrap='nowrap' align='center'>
      <NewDropdown target={FilterButton} disableHoverOpen>
        <SimpleGrid cols={1}>
          {Object.values(filters).map(filter => {
            if (filter.input) {
              if (filter.input?.type === 'text') {
                return (
                  <FilterTextInput
                    key={filter.id}
                    filter={filter}
                    selected={selected[filter.id] ?? ''}
                    updateFilter={updateFilter}
                    allSelected={selected}
                  />
                )
              }
              if (filter.input?.type === 'phone') {
                return (
                  <FilterPhoneNumberInput
                    key={filter.id}
                    filter={filter}
                    selected={selected[filter.id] ?? ''}
                    updateFilter={updateFilter}
                  />
                )
              }
              if (filter.input?.type === 'minMaxDate') {
                return (
                  <FilterDateRangeInput
                    key={filter.id}
                    filter={filter}
                    selected={selected[filter.id] ?? ''}
                    updateFilter={updateFilter}
                  />
                )
              }
              if (filter.input?.type === 'checkbox') {
                return (
                  <FilterCheckbox
                    key={filter.id}
                    filter={filter}
                    selected={selected[filter.id] ?? ''}
                    updateFilter={updateFilter}
                    allSelected={selected}
                  />
                )
              }
              return (
                <FilterNumberInput
                  key={filter.id}
                  filter={filter}
                  selected={selected[filter.id] ?? ''}
                  updateFilter={updateFilter}
                  allSelected={selected}
                />
              )
            }
            return filter.multiSelect
              ? (
                <FilterMultiSelect
                  key={filter.id}
                  filter={filter}
                  selected={selected[filter.id]}
                  onChange={(values) => updateFilter(filter.id, values)}
                />
                )
              : (
                <FilterSelect
                  key={filter.id}
                  filter={filter}
                  selected={selected[filter.id]}
                  onChange={(value) => updateFilter(filter.id, value)}
              />
                )
          })}
          <TableFilterSaveControls />
        </SimpleGrid>
      </NewDropdown>
      <Group maw='65vw'>
        {Object.keys(selected).map(id => <BadgeContainer key={`badge-container-${id}`} id={id} filters={filters} selected={selected} removeFilterValue={removeFilterValue} />)}
      </Group>
    </Group>
  )
}

export const TableFilterSaveControls = memo(function TableFilterSaveControls () {
  const { saveFilters, clearSavedFilters, showSaveFilters, showClearSavedFilters } = useContext(TableFiltersContext)
  return (
    <Group justify='center'>
      <Tooltip label={showClearSavedFilters ? 'Clear Saved Filters' : 'No Filters Saved'}>
        <ActionIcon variant='subtle' aria-label='Clear Saved Filters' onClick={clearSavedFilters} disabled={!showClearSavedFilters} color='orange'>
          <IconSettingsOff />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={showSaveFilters ? 'Save Filters' : 'No Changes to Save'}>
        <ActionIcon variant='subtle' aria-label='Save Filters' onClick={saveFilters} disabled={!showSaveFilters} color='teal'>
          <IconSettingsAutomation />
        </ActionIcon>
      </Tooltip>
    </Group>
  )
})

function BadgeContainer ({ id, filters, selected, removeFilterValue }) {
  const filter = useMemo(() => _.find(filters, { id }), [filters, id])
  const filterSelected = selected[id] ?? null
  const activeOptions = useMemo(() => {
    const filterSelected = selected[id] ?? null
    return filter?.options?.filter(f => filterSelected?.constructor === Array ? filterSelected.includes(f.value) : filterSelected === f.value) ??
      (filter?.useValue ? (filterSelected?.constructor === Array ? filterSelected.map(elem => ({ label: elem, value: elem })) : ((filterSelected !== null) ? [{ label: (filter?.input?.type === 'phone') ? renderSelectedNumber(filterSelected) : filterSelected, value: filterSelected }] : null)) : null)
  }, [filter, filterSelected])

  return (filterSelected !== null) && activeOptions &&
    activeOptions.map((activeOption) => {
      return (
        <Badge
        key={`active-${filter?.label}-${activeOption?.label}`}
        leftSection={<ThemeIcon styles={{ root: { color: 'inherit' } }} variant='transparent' size='xs'>{filter?.leftSection}</ThemeIcon>}
        rightSection={<CloseButton styles={{ root: { color: 'inherit' } }} iconSize={'1rem'} onClick={() => removeFilterValue(id, activeOption.value)} />}
        variant='outline'
        py='xs'
        display='flex'
        color={filter.color ?? 'blue'}
        {...filter.badgeProps}
      >
        {filter.badgeProps?.label ?? filter?.label}: {activeOption?.label ?? ''}
      </Badge>
      )
    })
}
