/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import {
  ActionIcon,
  Center,
  Checkbox,
  NumberInput,
  Select,
  SimpleGrid,
  Table,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core'
import _ from 'lodash'
import { IconPlus, IconX } from '@tabler/icons-react'
import { randomId } from '@mantine/hooks'
import { getStatusSet, getStatusSets } from '../../../js/api/status_repository'
import TierSelect from './TierSelect'
import { formatToOptions } from '../../../js/util/DataUtil'
import { incompleteStatusName, makeIncompleteStatus } from './util';

function getNextPosition (statusSet) {
  return statusSet.statuses.length ? _.max(_.map(statusSet.statuses, status => status.position)) + 1 : 1
}

export default function StatusEditorTable ({ form, tiers, deleteStatus, statusSetId }) {
  const [statusSets, setStatusSets] = useState([])

  useEffect(() => {
    getStatusSets({ limit: 0 }).then(collection => setStatusSets(collection.items))
  }, [])

  const loadStatusSet = id => {
    if ((id !== null) && (id !== '-1')) {
      getStatusSet(id).then(statusSet => {
        form.setFieldValue('statuses', [...statusSet.statuses.map(status => ({
          key: randomId(),
          id: null,
          name: status.name,
          tier: status.tier.id.toString(),
          position: status.position,
          sequence: status.sequence,
          default_tier_status: status.default_tier_status,
          final: status.final
        })), ...(statusSet.ap_cycle_status_set ? [makeIncompleteStatus(getNextPosition(statusSet))] : [])])
      })
    } else {
      form.setFieldValue('statuses', [makeIncompleteStatus()])
    }
  }

  return (
    <SimpleGrid cols={1} spacing='xl'>
      {statusSetId === undefined && (
        <>
          <Select
            label='You can choose a template to start with'
            maxDropdownHeight={280}
            onChange={id => loadStatusSet(id)}
            defaultValue={'-1'}
            data={formatToOptions([
              { id: '-1', name: 'Start from scratch', group: 'Options' },
              ...statusSets
            ], { group: 'Status Sets' })}
          />
        </>
      )}
      <Table>
        <Table.Thead>
        <Table.Tr>
          <Table.Th width='10%'><Text>Position</Text></Table.Th>
          <Table.Th width='10%'><Tooltip label='The order in which a candidate will proceed throughout the process'><Text>Sequence</Text></Tooltip></Table.Th>
          <Table.Th><Text>Specific Status</Text></Table.Th>
          <Table.Th><Text>General Status</Text></Table.Th>
          <Table.Th width='5%'><Text>Default</Text></Table.Th>
          <Table.Th width='5%'><Tooltip label={'Whether this status is temporarily assigned or final'}><Text>Final</Text></Tooltip></Table.Th>
          <Table.Th width='5%'><Text>Actions</Text></Table.Th>
        </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
        {
          form.values.statuses.map((status, index) => (
            <Table.Tr key={status.key}>
              <Table.Td>
                <NumberInput min={1} {...form.getInputProps(`statuses.${index}.position`)}/>
              </Table.Td>
              <Table.Td>
                <NumberInput min={1} {...form.getInputProps(`statuses.${index}.sequence`)}/>
              </Table.Td>
              <Table.Td>
                <Tooltip label='The incomplete status is required for all hiring status sets' disabled={status.originalName !== incompleteStatusName}>
                  <TextInput {...form.getInputProps(`statuses.${index}.name`)} disabled={status.originalName === incompleteStatusName} />
                </Tooltip>
              </Table.Td>
              <Table.Td>
                <TierSelect tiers={tiers} form={form} index={index} disabled={status.originalName === incompleteStatusName} />
              </Table.Td>
              <Table.Td>
                <Center>
                  <Checkbox {...form.getInputProps(`statuses.${index}.default_tier_status`, { type: 'checkbox' }) } />
                </Center>
              </Table.Td>
              <Table.Td>
                <Center>
                  <Tooltip label='The incomplete status cannot be marked final' disabled={status.originalName !== incompleteStatusName}>
                    <Checkbox {...form.getInputProps(`statuses.${index}.final`, { type: 'checkbox' }) } disabled={status.originalName === incompleteStatusName} />
                  </Tooltip>
                </Center>
              </Table.Td>
              <Table.Td>
                <Center>
                  <ActionIcon color='gray' variant='danger-subtle' onClick={() => { deleteStatus(index) }} disabled={status.originalName === incompleteStatusName}><IconX size={18} /></ActionIcon>
                </Center>
              </Table.Td>
            </Table.Tr>
          ))
        }
        <Table.Tr>
          <Table.Td colSpan={8}>
            <ActionIcon
              color='blue'
              onClick={() => {
                form.insertListItem(
                  'statuses',
                  {
                    key: randomId(),
                    id: null,
                    name: '',
                    originalName: '',
                    tier: 1,
                    position: getNextPosition(form.values),
                    sequence: undefined,
                    default_tier_status: false,
                    final: false
                  })
              }}
            >
              <IconPlus size={18} />
            </ActionIcon>
          </Table.Td>
        </Table.Tr>
        </Table.Tbody>
      </Table>
    </SimpleGrid>
  )
}
