import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import RootReducer from './reducer'
import { apiSlice } from './api';

const env = document.querySelector('meta[name="environment"]')?.content ?? 'unknown'

const serializableConfig = { serializableCheck: { ignoredActions: ['assessments/uploadFile'] } }

export const setupStore = preloadedState => {
  return configureStore({
    preloadedState: preloadedState,
    reducer: RootReducer,
    middleware: getDefaultMiddleware => filteredWithLogger(getDefaultMiddleware)
      .concat(apiSlice.middleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'timer', timeout: 10 }
      })
  })
}

const filteredWithLogger = getMiddleware => (env === 'develop') ? getMiddleware(serializableConfig).concat(logger) : getMiddleware(serializableConfig)
