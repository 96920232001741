import { Button, Center, Grid, Group, Modal, Select, Table, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconStar } from '@tabler/icons-react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  deleteStatusSet,
  getClientStatusSets,
  getDefaultStatusSets,
  getStatusSets
} from '../../../js/api/status_repository'
import { formatToOptions } from '../../../js/util/DataUtil'
import * as styles from './StatusSetHome.module.scss'
import useOrganizations from './StatusSetHooks'
import { useAccount } from '../../util/Hooks'
import { Link } from 'react-router-dom'

export default function StatusSetHome () {
  const [statusSets, setStatusSets] = useState([])
  const [selected, setSelected] = useState('0')
  const [selectedStatusSet, setSelectedStatusSet] = useState(null)
  const [removeSelected, setRemoveSelected] = useState(null)
  const organizations = useOrganizations()
  const account = useAccount()

  useEffect(() => {
    setSelectedStatusSet(null)

    if (selected === '0') {
      getDefaultStatusSets().then(data => setStatusSets(data))
    } else if (selected === '-1') {
      getStatusSets({ limit: 0 }).then(collection => setStatusSets(collection.items))
    } else {
      getClientStatusSets(selected).then(data => setStatusSets(data.items))
    }
  }, [selected])

  const selectToDelete = selectedStatusSet => setRemoveSelected(selectedStatusSet)

  const remove = () => {
    deleteStatusSet(selectedStatusSet.id)
      .then(() => {
        showNotification({
          message: 'Successfully deleted status set',
          color: 'success'
        })

        setRemoveSelected(null)
        setSelectedStatusSet(null)

        if (selected === '0') {
          getDefaultStatusSets().then(data => setStatusSets(data))
        } else if (selected === '-1') {
          getStatusSets({ limit: 0 }).then(collection => setStatusSets(collection.items))
        } else {
          getClientStatusSets(selected).then(data => setStatusSets(data.items))
        }
      })
      .catch(err => {
        setRemoveSelected(null)

        if (err.response.status === 406) {
          showNotification({
            title: 'Something went wrong',
            message: 'Can\'t delete! There are applicants or a cycle using this status set',
            color: 'red'
          })
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error deleting this status set',
            color: 'red'
          })
        }
      })
  }

  return (
    <>
      <Modal opened={removeSelected !== null} onClose={() => setRemoveSelected(null)} title='Are you sure you want to delete this status set?'>
        <Group>
          <Button color='red' type='submit' onClick={() => remove() }>Delete</Button>
          <Button color='gray' onClick={() => setRemoveSelected(null)}>Cancel</Button>
        </Group>
      </Modal>
      <Grid>
        <Grid.Col span={{ lg: 5, md: 12 }}>
          <Select
            data={formatToOptions(
              [
                { id: '0', name: 'General status sets', group: 'General' },
                { id: '-1', name: 'All status sets', group: 'General' },
                ...organizations
              ],
              { group: 'By organization' }
            )}
            defaultValue={selected}
            onChange={setSelected}
            searchable
            nothingFoundMessage='No options'
            maxDropdownHeight={280}
            />
        </Grid.Col>
        <Grid.Col span={{ lg: 7, md: 12 }}>
          <Group justify='flex-end'>
            <Button component={Link} to='/build/status-sets/create' color='success'>Create new</Button>
            <Button component={Link} to={`/build/status-sets/${selectedStatusSet?.id}/edit`} disabled={!selectedStatusSet || (!(account?.access.DEVELOPER ?? false) && selectedStatusSet.default_cycle_status_set && selectedStatusSet.client === undefined)}>Edit selected</Button>
            <Button color='red' onClick={() => selectToDelete()} disabled={!selectedStatusSet || !(account?.access.DEVELOPER ?? false) || (selectedStatusSet.default_cycle_status_set && selectedStatusSet.client === undefined)}>Delete selected</Button>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          <Table highlightOnHover className={styles.statusSetTable}>
            <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Description</Table.Th>
              <Table.Th width='10%'><Text align='center'>Default</Text></Table.Th>
            </Table.Tr>
            </Table.Thead>
            <tbody>
            {
              statusSets.map(statusSet => (
                <Table.Tr key={`status-set-${statusSet.id}`} onClick={() => setSelectedStatusSet(statusSet)} className={selectedStatusSet === statusSet ? styles.selected : ''}>
                  <Table.Td>{statusSet.name}</Table.Td>
                  <Table.Td>{statusSet.description}</Table.Td>
                  <Table.Td>{statusSet.default_cycle_status_set && <Center><IconStar size={20} color='orange'/></Center>}</Table.Td>
                </Table.Tr>
              ))
            }
            </tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={{ lg: 6, md: 12 }}>
          {
            selectedStatusSet !== null && (
              <Table className={styles.statusTable} withTableBorder>
                <Table.Thead>
                <Table.Tr>
                  <Table.Th>Sequence</Table.Th>
                  <Table.Th>Specific Status</Table.Th>
                  <Table.Th>General Status</Table.Th>
                  <Table.Th>Final</Table.Th>
                </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                {
                  _.map(_.sortBy(selectedStatusSet.statuses, ['position']), status => (
                    <Table.Tr key={`status-${status.id}`}>
                      <Table.Td><Center>{status.sequence}</Center></Table.Td>
                      <Table.Td>{status.name}</Table.Td>
                      <Table.Td>{status.tier.name}</Table.Td>
                      <Table.Td><Center>{ status.final && <IconCheck size={16}/> }</Center></Table.Td>
                    </Table.Tr>
                  ))
                }
                </Table.Tbody>
              </Table>
            )
          }
        </Grid.Col>
      </Grid>
    </>
  )
}
