import {
  IconActivity,
  IconAdjustments,
  IconAffiliate,
  IconBan,
  IconCalendarDue,
  IconCalendarTime,
  IconChartAreaLine,
  IconCircleCheck,
  IconClipboardText,
  IconCopy,
  IconCurrentLocation,
  IconDatabaseExport,
  IconFiles,
  IconFileSpreadsheet,
  IconInputSearch,
  IconLink,
  IconLocation,
  IconMail,
  IconMailbox,
  IconMailOpened,
  IconPencil,
  IconPercentage,
  IconPhoneCheck,
  IconRefresh,
  IconRocket,
  IconSchool,
  IconSettings,
  IconSettingsAutomation,
  IconTableExport,
  IconTableImport,
  IconTrash,
  IconTrendingUp2,
  IconUserEdit,
  IconUserPlus,
  IconUsers,
  IconUserUp
} from '@tabler/icons-react'
import { equals } from '../../../util/float.util.js'
import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { CyclePassRoute } from '../../../js/generated/enums/CyclePassRoute'
import { Header, NumberCell, RezviewCell } from '../../core/ReactTable/ReactTable'
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Flex,
  Group,
  Highlight,
  HoverCard,
  Text,
  Tooltip
} from '@mantine/core'
import SelectableColumn from '../../core/ReactTable/SelectableColumn'
import dayjs from 'dayjs'
import {
  DetailViewScoreCell,
  formatScoreDisplay
} from './DetailViewScoreCell'
import { useDispatch } from 'react-redux'
import { openModal } from './detailViewSlice'
import { DetailViewNotesCell } from './DetailViewNotesCell'
import { DetailViewStatusCell } from './DetailViewStatusCell'
import { DropdownListContent } from '../../core/DropdownListContent.js'
import CycleSubscribe from './CycleSubscribe.js'
import { pushToIcims } from '../../../js/api/cycle_repository.js'
import { useDisclosure } from '@mantine/hooks'
import { NewDropdown } from '../../core/NewDropdown.js'
import CycleAccessModal from './CycleAccessModal.jsx'
import { CycleStageReplacementAppExternalWrapper } from './invites/stages/CycleStageReplacementApp.js'
import { incompleteStatusName } from '../../build/status_set/util';
import { CopyableContent } from './CopyableContent';

const ICON_SIZE = '1.25rem'
const ROW_HEIGHT = '5rem'

export function useCycleActions (id, hasAdminAccess, hasClientAccess, hasBetaAccess, meta = {}) {
  return useMemo(() => {
    const _actions = []
    const exports = {
      label: 'Exports',
      metadata: {
        items: [
          {
            label: 'Export Summaries',
            leftSection: <IconDatabaseExport size={ICON_SIZE} />,
            href: `/cycles/${id}/summaries`
          },
          {
            label: 'Applicant Results',
            leftSection: <IconFileSpreadsheet size={ICON_SIZE} />,
            href: `/cycles/${id}/fullExport`
          },
          {
            label: 'Custom Export',
            leftSection: <IconTableExport size={ICON_SIZE} />,
            href: `/cycles/${id}/customExport`
          },
          {
            label: 'Duplicate Report',
            leftSection: <IconCopy size={ICON_SIZE} />,
            href: `/cycles/${id}/duplicateFlagReport`
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (exports.metadata.items.length > 0) {
      _actions.push(exports)
    }

    const cycleManagement = {
      label: 'Cycle Management',
      metadata: {
        defaultOpened: true,
        items: [
          {
            label: 'Edit Cycle',
            leftSection: <IconPencil size={ICON_SIZE} />,
            href: '/hire/report-results/' + id + '/edit',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Cycle Specs',
            leftSection: <IconAdjustments size={ICON_SIZE} />,
            href: '/hire/report-results/' + id + '/specs',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Update Cycle',
            leftSection: <IconCalendarDue size={ICON_SIZE} />,
            href: `/cycles/${id}/update`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Refresh Cycle',
            leftSection: <IconRefresh size={ICON_SIZE} />,
            href: `/cycles/${id}/refresh`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Delete Cycle',
            leftSection: <IconTrash size={ICON_SIZE} />,
            href: `/cycles/${id}/delete`,
            variant: 'danger-subtle',
            color: 'black',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Close Cycle',
            leftSection: <IconBan size={ICON_SIZE} />,
            href: `/cycles/${id}/close`,
            metadata: {
              isGranted: hasClientAccess && !hasAdminAccess
            }
          },
          {
            label: 'Triggers',
            leftSection: <IconSettingsAutomation size={ICON_SIZE} />,
            href: `/hire/cycles/${id}/triggers`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Batteries',
            leftSection: <IconClipboardText size={ICON_SIZE} />,
            href: `/hire/cycles/${id}/batteries`,
            metadata: {
              isGranted: hasAdminAccess
            }
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }

    if (cycleManagement.metadata.items.length > 0) {
      _actions.push(cycleManagement)
    }

    const candidateManagement = {
      label: 'Candidate Management',
      metadata: {
        defaultOpened: true,
        items: [
          {
            label: 'Bulk Edit Applicants',
            leftSection: <IconUserEdit size={ICON_SIZE} />,
            href: `/cycles/${id}/edit/applicants`,
            metadata: {
              isGranted: (hasAdminAccess || hasClientAccess)
            }
          },
          {

            label: 'Add Applicant',
            leftSection: <IconUserPlus size={ICON_SIZE} />,
            href: `/cycles/${id}/applicants/create`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Import Applicants',
            leftSection: <IconUserUp size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/applicants/import`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {
            label: 'Invites (Beta)',
            leftSection: <IconTableImport size={ICON_SIZE} />,
            href: generatePath(CyclePassRoute.CycleInvitesHome, { cycleId: id }),
            metadata: {
              isGranted: hasBetaAccess && hasAdminAccess
            }
          },
          {

            label: 'Contact Applicants',
            leftSection: <IconMail size={ICON_SIZE} />,
            href: `/cycles/${id}/contact`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {

            label: 'Boost Cycle',
            leftSection: <IconRocket size={ICON_SIZE} />,
            href: `/cycles/${id}/invite`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {

            label: 'Message History',
            leftSection: <IconMailOpened size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/message-history`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Files',
            leftSection: <IconFiles size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/files`
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (candidateManagement.metadata.items.length > 0) {
      _actions.push(candidateManagement)
    }

    const overview = {
      label: 'Overview',
      metadata: {
        items: [
          {

            label: 'Job Posting Site',
            leftSection: <IconLink size={ICON_SIZE} />,
            href: `${meta.job_posting_site}`
          },
          {

            label: 'Analytics',
            leftSection: <IconChartAreaLine size={ICON_SIZE} />,
            href: `/cycles/${id}/analytics`
          },
          {

            label: 'Integrations',
            leftSection: <IconAffiliate size={ICON_SIZE} />,
            href: `/hire/cycle/${id}/integrations`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            component: Button,
            label: 'Users with Access',
            leftSection: <IconUsers size='1.25rem' />,
            onClick: meta.openUserAccessModal,
            metadata: {
              isGranted: hasAdminAccess
            }
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (overview.metadata.items.length > 0) {
      _actions.push(overview)
    }

    return _actions
  }, [hasAdminAccess, hasClientAccess, hasBetaAccess, id, meta])
}

const DETAIL_TABLE_ABSTRACT_ORDER_VARIANTS = {
  score: 'orderScore'
}

function IcimsButton ({ onClick }) {
  return (
    <Tooltip label='Push cycle data to iCIMS'>
      <Button
        size='compact-sm'
        onClick={onClick}
      >
        iCIMS Push
      </Button>
    </Tooltip>
  )
}

function useTableActionsComponent (cycleId, hasAdminAccess, hasClientAccess, hasBetaAccess, meta) {
  const container = document.getElementById('react-app-container')
  const hasIcimsAccess = container.hasAttribute('data-has-icims-access')
  const subscribed = container.hasAttribute('data-is-subscribed')

  const [cycleAccessOpened, { open: openCycleAccess, close: closeCycleAccess }] = useDisclosure(false)
  const items = useCycleActions(cycleId, hasAdminAccess, hasClientAccess, hasBetaAccess, { job_posting_site: meta.job_posting_site, openUserAccessModal: openCycleAccess })

  return (
    <>
      <CycleAccessModal cycleId={cycleId} hasAdminAccess={hasAdminAccess} opened={cycleAccessOpened} close={closeCycleAccess} />
      <NewDropdown target={<Button variant='filled' leftSection={<IconSettings />}>Cycle Actions</Button>}>
        <DropdownListContent items={items}>
          {(hasAdminAccess || hasIcimsAccess) &&
          <Flex>
            <Group>
              {hasIcimsAccess && <IcimsButton onClick={() => pushToIcims(cycleId)} />}
              {hasAdminAccess && <CycleSubscribe id={cycleId} isSubscribed={subscribed} />}
            </Group>
          </Flex>}
        </DropdownListContent>
      </NewDropdown>
      <CycleStageReplacementAppExternalWrapper cycleId={cycleId} />
    </>
  )
}

export function useCycleApplicantsDetailListTable (namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, educationOptions = null, defaultDisplayType) {
  return {
    defaultHiddenColumns: useDetailViewDefaultHiddenColumns(account.access.DEMO, account.access.ADMIN),
    defaultFilters: useDefaultCycleDetailListFilters(defaultDisplayType),
    columns: useAllDetailViewColumns(namespace, cycleId, account, moduleInfos),
    filters: useCycleDetailListFilters(account.access.ADMIN, statuses, statusTiers, regions, educationOptions),
    actions: getCycleDetailTableActions(cycleId, account.access),
    tableActionsComponent: useTableActionsComponent(cycleId, account.access.ADMIN, account.access.CLIENT, account.access.BETA, { job_posting_site: account.job_posting_site, openUserAccessModal: account.openUserAccessModal }),
    searchable: true,
    alwaysShowHeaderGroups: false,
    showHeaderGroupsIfExpanded: false,
    abstractOrderVariants: DETAIL_TABLE_ABSTRACT_ORDER_VARIANTS,
    noBorders: true
  }
}

function useDefaultCycleDetailListFilters (defaultDisplayType) {
  return useMemo(() => ({
    display: defaultDisplayType,
    excludeIncomplete: '1'
  }), [defaultDisplayType])
}

const getCycleDetailTableActions = (cycleId, access) => {
  const hasAdminAccess = access.ADMIN?.toString() ?? undefined

  return [
    {
      component: Anchor,
      label: 'Edit',
      leftSection: <IconPencil />,
      href: (id) => `/cycles/${cycleId}/edit/applicants/${id}`
    },
    {
      component: Anchor,
      label: 'Candidate Report',
      leftSection: <IconFileSpreadsheet />,
      href: (id) => `/hire/cycles/${cycleId}/applicants/${id}/report`
    },
    {
      component: Anchor,
      label: 'Keysurvey Responses',
      leftSection: <IconClipboardText />,
      href: (id) => `/cycles/${cycleId}/applicants/${id}/responses`,
      visible: hasAdminAccess
    },
    {
      component: Anchor,
      label: 'Contact Applicant',
      leftSection: <IconMail />,
      href: (id) => `/cycles/${cycleId}/contact/${id}`
    },
    {
      component: Anchor,
      label: 'Files',
      leftSection: <IconFiles />,
      href: (id) => `/hire/report-results/${cycleId}/applicants/${id}/files`
    },
    {
      component: Anchor,
      label: 'Refresh/Delete Applicant',
      leftSection: <IconTrash />,
      href: (id) => `/hire/report-results/${cycleId}/applicants/${id}/delete`,
      visible: hasAdminAccess,
      color: 'orange'
    }
  ]
}

const DISPLAY_TYPE_FILTER_OPTIONS = Object.freeze([
  Object.freeze({ label: 'Percentile', value: 'percentile' }),
  Object.freeze({ label: 'Mixed', value: 'mixed' }),
  Object.freeze({ label: 'Raw Score', value: 'rawscore' })
])

const DISPLAY_TYPE_PM_FILTER_OPTIONS = Object.freeze([
  ...DISPLAY_TYPE_FILTER_OPTIONS,
  Object.freeze({ label: 'Z Score', value: 'zscore' })
])

const SEARCH_FIELD_OPTIONS = Object.freeze([
  { label: 'All', value: 'all' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Name', value: 'name' },
  { label: 'City', value: 'city' },
  { label: 'Email', value: 'email' }
])

const statusFilterId = 'status'

const INCOMPLETE_FILTER = {
  id: 'excludeIncomplete',
  label: 'First Phase',
  inputLabel: 'First Phase Complete',
  leftSection: <IconCircleCheck />,
  options: [{ label: 'Complete', value: '1' }],
  input: {
    type: 'checkbox'
  },
  description: 'Only display applicants which have completed the entire first phase.',
  disabledDescription: 'Selected incomplete status option overrides this filter.',
  getDisabled: (allSelected) => {
    const statusSelected = allSelected[statusFilterId] ?? null
    console.debug('Checking if excludeIncomplete filter should be disabled', { statusSelected, allSelected })
    if (!statusSelected || (!statusSelected.length && (statusSelected !== incompleteStatusName))) {
      return false
    }
    return statusSelected.includes(incompleteStatusName)
  }
}

const CYCLE_DETAIL_LIST_SHARED_FILTERS = [
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    leftSection: <IconPhoneCheck />,
    input: {
      type: 'phone'
    },
    placeholder: 'Enter phone number',
    errorText: 'Enter a valid phone number',
    useValue: true
  },
  {
    id: 'submitDate',
    label: 'Submit Date',
    leftSection: <IconCalendarTime />,
    input: {
      type: 'minMaxDate'
    },
    useValue: true
  },
  {
    id: 'courtesyLetter',
    label: 'Courtesy Letter',
    leftSection: <IconMail />,
    options: [
      { label: 'Sent', value: '1' },
      { label: 'Not Sent', value: '0' }
    ]
  },
  {
    id: 'searchField',
    label: 'Search Field',
    leftSection: <IconInputSearch />,
    options: SEARCH_FIELD_OPTIONS,
    placeholder: 'Current: All',
    clearOptionValue: 'all'
  },
  INCOMPLETE_FILTER
]

const CYCLE_DETAIL_LIST_FILTERS = [
  {
    id: 'display',
    label: 'Display Type',
    leftSection: <IconPercentage />,
    options: DISPLAY_TYPE_FILTER_OPTIONS
  },
  ...CYCLE_DETAIL_LIST_SHARED_FILTERS
]

const CYCLE_DETAIL_LIST_PM_FILTERS = [
  {
    id: 'display',
    label: 'Display Type',
    leftSection: <IconPercentage />,
    options: DISPLAY_TYPE_PM_FILTER_OPTIONS
  },
  ...CYCLE_DETAIL_LIST_SHARED_FILTERS
]

const STATUS_FILTER_NO_OPTIONS = {
  id: statusFilterId,
  label: 'Status',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__',
  multiSelect: true
}

const TIER_FILTER_NO_OPTIONS = {
  id: 'tier',
  label: 'Tiering',
  leftSection: <IconTrendingUp2 />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__',
  multiSelect: true
}

const REGION_FILTER_NO_OPTIONS = {
  id: 'region',
  label: 'Location',
  leftSection: <IconLocation />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__'
}

const EDUCATION_FILTER_NO_OPTIONS = {
  id: 'degree',
  label: 'Highest Education',
  leftSection: <IconSchool />,
  multiSelect: true,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__'
}

const zipCodeFilterId = 'zipCode'

function isAllNumbers (value) {
  return /^\d+$/.test(value)
}

function isZipCode (value) {
  if (value.length === 5) {
    return isAllNumbers(value)
  }
  if (value.length === 10) {
    const replaced = value.replace('-', '')
    return (replaced.length !== 10) && isZipCode(replaced)
  }
  if (value.length === 9) {
    return isAllNumbers(value)
  }
  return false
}

const ZIP_FILTER_INPUT = {
  id: zipCodeFilterId,
  label: 'Zip Code',
  leftSection: <IconMailbox />,
  input: {
    type: 'text'
  },
  placeholder: 'Enter zip code',
  validateUpdate: (value) => !value || isZipCode(value),
  errorText: 'Enter a valid Zip Code',
  useValue: true
}

const RADIUS_FILTER_INPUT = {
  id: 'radius',
  label: 'Radius',
  leftSection: <IconCurrentLocation />,
  input: {
    type: 'number'
  },
  getDisabled: (allSelected) => !(allSelected[zipCodeFilterId] ?? null) || !isZipCode(allSelected[zipCodeFilterId]),
  getRequired: (allSelected) => !!(allSelected[zipCodeFilterId] ?? null) && isZipCode(allSelected[zipCodeFilterId]),
  numberProps: {
    min: 1,
    max: 500,
    step: 5,
    startValue: 50,
    clampBehavior: 'strict'
  },
  placeholder: 'Enter miles',
  useValue: true
}

function toTitleCase (str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function useCycleDetailListFilters (isPM, statuses, statusTiers, regions, educationOptions = null) {
  return useMemo(() => {
    const statusFilter = {
      ...STATUS_FILTER_NO_OPTIONS,
      options: [{ label: 'All', value: STATUS_FILTER_NO_OPTIONS.clearOptionValue }, ...statuses.items.map(status => ({ label: status.name, value: status.name }))]
    }
    const tierFilter = {
      ...TIER_FILTER_NO_OPTIONS, options: [{ label: 'All', value: TIER_FILTER_NO_OPTIONS.clearOptionValue }, ...statusTiers.map(tier => ({ label: toTitleCase(tier.name), value: tier.id.toString() }))]
    }
    const regionsFilters = regions.items?.length
      ? [{
          ...REGION_FILTER_NO_OPTIONS, options: [{ label: 'All', value: REGION_FILTER_NO_OPTIONS.clearOptionValue }, ...regions.items.map(region => ({ label: region, value: region }))]
        }]
      : []
    const simpleViewFilters = educationOptions?.items?.length
      ? [{
          ...EDUCATION_FILTER_NO_OPTIONS, options: [{ label: 'All', value: EDUCATION_FILTER_NO_OPTIONS.clearOptionValue }, ...educationOptions.items.map(education => ({ label: education, value: education }))]
        }, ZIP_FILTER_INPUT, RADIUS_FILTER_INPUT]
      : [ZIP_FILTER_INPUT, RADIUS_FILTER_INPUT]
    const generatedFilters = [statusFilter, tierFilter, ...regionsFilters, ...simpleViewFilters]
    return isPM ? [...generatedFilters, ...CYCLE_DETAIL_LIST_PM_FILTERS] : [...generatedFilters, ...CYCLE_DETAIL_LIST_FILTERS]
  }, [isPM, statuses, statusTiers, regions, educationOptions])
}

const ID_COLUMNS = [
  {
    Header: <Header>ID</Header>,
    headerLabel: 'ID',
    id: 'id',
    accessor: (row) => row,
    Cell: IdCell,
    sortable: false,
    filterable: false,
    hideable: true
  }
]

function IdCell ({ cell: { value } }) {
  return (
    <>
      <CopyableContent contents={value.id} />
      {!!value.ks_respondent?.ks_respondent_id && (<><Text ta='center'>KS: </Text><CopyableContent contents={value.ks_respondent.ks_respondent_id} /></>)}
    </>
  )
}

function getIdColumns (isPm) {
  return isPm ? ID_COLUMNS : []
}

const PERSONAL_COLUMNS = [
  {
    Header: <Header>Name</Header>,
    headerLabel: 'Name',
    id: 'name',
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      const joinedName = (value.first_name ?? '') + (value.first_name && value.last_name ? ' ' : '') + (value.last_name ?? '')
      const joinedAddress = (value.contact_information?.city ?? '') + (value.contact_information?.city && value.contact_information?.state ? ', ' : '') + (value.contact_information?.state ?? '')
      return ( // TODO [detail rework full] show fake resume/summary modal on name click
        <RezviewCell name={joinedName} applicantId={value.id} address={joinedAddress} />
      )
    },
    miw: '15rem',
    sortable: true,
    hideable: true,
    filterable: false,
    align: 'left'
  },
  {
    Header: <Header centered>Submit Date</Header>,
    headerLabel: 'Submit Date',
    id: 'submitDate',
    accessor: 'submitted_at',
    Cell: ({ cell: { value } }) => {
      return (
        <Flex justify='center' align='center'>
          <Tooltip label='Submitted Date' position='top'>
            <Text ta='center' m='auto'>{dayjs(value).format('MM/DD/YYYY')}</Text>
          </Tooltip>
        </Flex>
      )
    },
    sortable: true,
    hideable: true,
    filterable: false,
    align: 'center'
  },
  {
    Header: <Header>Highest Degree</Header>,
    headerLabel: 'Highest Degree',
    id: 'highestDegree',
    accessor: 'education',
    Cell: ({ cell: { value } }) => {
      const degreeAnswer = value?.highest_degree?.answer
      return (
        <Text ta='left'>{degreeAnswer || '-'}</Text>
      )
    },
    sortable: false,
    hideable: true,
    filterable: false
  }
]

export const STATUS_COLUMN_WIDTH = '9rem'

const STATUS_COLUMN = {
  Header: <Header centered>Status</Header>,
  headerLabel: 'Status',
  id: 'status',
  accessor: (row) => row,
  Cell: DetailViewStatusCell,
  sortable: false,
  width: STATUS_COLUMN_WIDTH,
  hideable: true,
  filterable: false,
  align: 'center'
}

const FLAG_COLUMN = {
  Header: <Header centered>Flags</Header>,
  headerLabel: 'Flags',
  id: 'flags',
  accessor: 'flags',
  Cell: ({ cell: { row, value } }) => (
    <FlagsCell applicantId={row.original.id} flags={value} />
  ),
  sortable: false,
  hideable: true,
  filterable: false,
  align: 'center'
}

function FlagsCell ({ applicantId, flags }) {
  const dispatch = useDispatch()

  const handleClick = (applicantId) => {
    dispatch(openModal({ modal: 'flags', id: applicantId }))
  }

  const hasFlags = flags.length > 0

  return (
  <NumberCell centered>
      {hasFlags
        ? <Box onClick={(e) => hasFlags && e.stopPropagation()}>
          <Tooltip label='Click to View' position='top'>
              <ActionIcon size='lg' color='blue' onClick={() => handleClick(applicantId)}>{flags.length}</ActionIcon>
          </Tooltip>
        </Box>
        : <Box c='dimmed'>0</Box>
      }
  </NumberCell>
  )
}

const FINAL_DETAIL_COLUMNS_NOTES = [
  {
    Header: (
      <Header centered>
        <HoverCard closeDelay={500} openDelay={150} shadow='md' defaultOpened={false}>
          <HoverCard.Target>
            <Box c='blue' style={{ cursor: 'help' }}>Notes</Box>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text>Notes are private and will not be seen by anyone except you and authorized account members.</Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </Header>
    ),
    headerLabel: 'Notes',
    id: 'notes',
    accessor: 'comments',
    Cell: ({ cell: { value, row } }) => <DetailViewNotesCell value={value} row={row} mah={ROW_HEIGHT} />,
    sortable: false,
    miw: '10rem',
    hideable: true,
    filterable: false
  },
  STATUS_COLUMN,
  FLAG_COLUMN
]

const DEMOGRAPHIC_COLUMNS = [
  {
    Header: <Header>Gender</Header>,
    headerLabel: 'Gender',
    id: 'gender',
    accessor: 'demographic.gender_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value ?? ''}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Race</Header>,
    headerLabel: 'Race',
    id: 'race',
    accessor: 'demographic.race_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value ?? ''}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Ethnicity</Header>,
    headerLabel: 'Ethnicity',
    id: 'ethnicity',
    accessor: 'demographic.ethnicity_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

function buildModuleColumn (namespace, cycleId, account, moduleInfo, child = false, collapsedGroup = null, isExpandedSummary = false) {
  const nestedModuleColumns = []
  if (moduleInfo.sub_modules?.length) {
    nestedModuleColumns.push(...buildModuleColumn(namespace, cycleId, account, { ...moduleInfo, sub_modules: [] }, true, null, true))
    for (const moduleChild of moduleInfo.sub_modules) {
      nestedModuleColumns.push(...buildModuleColumn(namespace, cycleId, account, moduleChild, true))
    }
  }
  console.debug('Nested module columns for info.', { moduleInfo, nestedModuleColumns })
  const moduleName = moduleInfo.module.name ?? 'Name Missing'
  const moduleHasMean = !!moduleInfo.std_dev && (parseFloat(moduleInfo.std_dev) !== 0.0) && !!moduleInfo.mean && (parseFloat(moduleInfo.mean) !== 0.0)
  const [moduleMinScoreString, moduleMinScore] = ((moduleInfo.min_score ?? null) !== null) ? formatScoreDisplay(moduleInfo.min_score) : ['0', 0.0]
  const moduleHasMinScore = moduleHasMean && ((moduleInfo.min_score ?? null) !== null)
  const moduleHasLink = !!(moduleInfo.generated_link && moduleInfo.generated_link_name)
  const moduleHasTooltip = !!(moduleHasMean || moduleHasLink)
  const moduleNameHighlighted = moduleHasTooltip ? moduleName.split(' ') : []
  const moduleTooltipTargetHighlightColor = moduleHasLink ? 'linear-gradient(45deg, var(--mantine-color-blue-7), var(--mantine-color-blue-6))' : 'linear-gradient(180deg, var(--mantine-color-blue-7), var(--mantine-color-blue-7))'
  const moduleColumn = {
    Header: (
      <>
        {moduleHasTooltip
          ? (
            <HoverCard closeDelay={500} openDelay={150} shadow='md' defaultOpened={false}>
              <HoverCard.Target>
                <Box style={{ cursor: 'help' }} ta='center'>
                  <Highlight
                    ta="center"
                    highlight={moduleNameHighlighted}
                    highlightStyles={{
                      backgroundImage: moduleTooltipTargetHighlightColor,
                      fontWeight: 700,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    {moduleName}
                  </Highlight>
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                {moduleHasMean && <Group justify='center' gap='xxs'><Text fw={700}>Max Raw Score: </Text><Text fw={300}>{formatScoreDisplay(moduleInfo.max_score)[0]}</Text></Group>}
                {moduleHasMinScore && <Group justify='center' gap='xxs'><Text fw={700}>Cut Score: </Text><Text fw={300}>{moduleMinScoreString}</Text></Group>}
                {moduleHasLink && <Group justify='center'><Anchor href={moduleInfo.generated_link} target='_blank'>{moduleInfo.generated_link_name}</Anchor></Group>}
              </HoverCard.Dropdown>
            </HoverCard>
            )
          : (
          <Header centered>
            {moduleName}
          </Header>
            )}
      </>
    ),
    headerLabel: moduleInfo.module.name ?? 'Module Name Missing',
    abstractOrder: 'score',
    id: moduleInfo.module.id.toString() + (isExpandedSummary ? '-expandedTotal' : '') + (nestedModuleColumns.length ? '-group' : ''),
    sortId: moduleInfo.module.id.toString(),
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      const scoreValue = value.scores.filter(elem => elem.module_id === moduleInfo.module.id)[0] ?? { score: '-', z_score: '-', percentile_score: '-' }
      const applicantLink = ((moduleInfo.generate_applicant_link_always ?? null) === null) ? null : ((moduleInfo.generate_applicant_link_always || !!value.ks_respondent?.ks_respondent_id) ? `/hire/cycles/${cycleId}/applicants/${value.id}/assessments/${moduleInfo.id}/report` : null)
      const isPercentileOnly = equals(moduleInfo.mean, 0.0) && equals(moduleInfo.std_dev, 0.0)

      return <DetailViewScoreCell
        value={scoreValue}
        moduleInfoType={moduleInfo.type.id}
        moduleId={moduleInfo.module.id}
        precision={moduleInfo.precision}
        namespace={namespace}
        applicantLink={applicantLink}
        moduleMinScore={moduleMinScore}
        mah={ROW_HEIGHT}
        isPercentileOnly={isPercentileOnly}
      />
    },
    sortable: !nestedModuleColumns.length,
    filterable: false,
    width: '8rem',
    hideable: !nestedModuleColumns.length && !child,
    _hideable: (!!nestedModuleColumns.length || (!nestedModuleColumns.length && !collapsedGroup)) && !child, // TODO [refactor all tables] remove legacy prop once collapsedGroup is removed hideable above can be changed to this
    collapsable: !!nestedModuleColumns.length,
    collapsedGroup: collapsedGroup,
    defaultSortOrder: 'DESC',
    summaryColumnGroup: isExpandedSummary ? moduleInfo.module.id.toString() + '-group' : null,
    phase: moduleInfo.phase?.name ?? null,
    align: 'center',
    ...(nestedModuleColumns.length ? { columns: nestedModuleColumns } : {})
  }
  return nestedModuleColumns.length ? [...buildModuleColumn(namespace, cycleId, account, { ...moduleInfo, sub_modules: [] }, child, moduleColumn.id), moduleColumn] : [moduleColumn]
}

function buildModuleColumns (namespace, cycleId, account, moduleInfos) {
  const allModuleColumns = []
  const generatedColumnGroups = moduleInfos.items.map(moduleInfo => buildModuleColumn(namespace, cycleId, account, moduleInfo))
  generatedColumnGroups.forEach(elem => allModuleColumns.push(...elem))
  return allModuleColumns
}

export function useAllDetailViewColumns (namespace, cycleId, account, moduleInfos) {
  return useMemo(() => {
    return [
      ...getDetailViewSelectionColumns(),
      ...getIdColumns(account.access.ADMIN),
      ...PERSONAL_COLUMNS,
      ...buildModuleColumns(namespace, cycleId, account, moduleInfos),
      ...getDetailViewDemographicColumns(account.access.DEMO),
      ...FINAL_DETAIL_COLUMNS_NOTES
    ]
  }, [namespace, cycleId, account, moduleInfos])
}

function getDetailViewDemographicColumns (canDiscoverDemo) {
  if (!canDiscoverDemo) {
    return []
  }
  return DEMOGRAPHIC_COLUMNS
}

function getDetailViewSelectionColumns () {
  return [
    SelectableColumn({
      selectAriaLabel: 'Select applicant',
      selectAllAriaLabel: 'Select all applicants',
      id: 'checkbox',
      accessor: 'id'
    })
  ]
}

export function useDetailViewDefaultHiddenColumns (canDiscoverDemo, isPm = false) {
  return useMemo(() => {
    if (isPm) {
      return canDiscoverDemo ? DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO_ADMIN : DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS_ADMIN
    }
    return canDiscoverDemo ? DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO : DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS
  }, [canDiscoverDemo, isPm])
}

const DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS = Object.freeze([
  'highestDegree'
])

const DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO = Object.freeze([
  ...DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS,
  'gender',
  'race',
  'ethnicity'
])

const DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS_ADMIN = Object.freeze([
  'id',
  ...DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS
])

const DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO_ADMIN = Object.freeze([
  'id',
  ...DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO
])
