import { Box, List, Text, Title } from '@mantine/core';
import React from 'react';

export function DemographicsTooltipLabel ({ maw = '50vw' }) {
  return (
    <Box maw={maw} p='md'>
      <Title c='white' fw={400}>
        You are required to collect demographic data from job applicants if your organization meets <Text fw={700} c='blue' inherit span>BOTH</Text> of the following conditions:
      </Title>
      <List>
        <List.Item>
          <Text>
            <Text fw={700} inherit span>Government contracts of $50,000 or more: </Text>
            This applies to federal supply and service contractors or subcontractors who hold a single contract,
            subcontract, or purchase order of $50,000 or more (or meet other specific criteria, like government
            bills of lading totaling $50,000+ in a 12-month period, serving as a depository of government funds,
            or being a financial institution issuing U.S. savings bonds).
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            <Text fw={700} inherit span>50 or more employees: </Text>Your organization must employ 50 or more people.
          </Text>
        </List.Item>
      </List>
    </Box>
  )
}
