/* eslint react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Tooltip } from '@mantine/core';

/**
 * @typedef {Object} InputFilter
 * @property {string} label Label used for rendering in badges and control
 * @property {?string} inputLabel Optional - display value used to override control label.
 * @property {?string} description Sub-label used for rendering in filter control menu as a tooltip.
 * @property {?string} disabledDescription Sub-label used for rendering in filter control menu as tooltip when disabled.
 * @property {string} id Id used as query parameter name.
 * @property {?string} color Optional, color used for badge if any option is selected.
 * @property {?object} badgeProps Optional, props to pass to the badge component when an option is selected.
 * @property {?array} options Optional, array of objects containing { label: string, value: string } indicating possible filter values. Should just have one option with a value of '1' for the checkbox type.
 * @property {?any} leftSection Optional, node passed to left section of badges.
 * @property {?any} rightSection Optional, node passed to right section of badges.
 * @property {?function} getDisabled Optional, first argument is object mapping all selected values. Returns true if this filter should be disabled.
 * @property {?boolean} useValue Optional, indicates badge should show the input value rather than searching options for a matching label.
 * @property {?object} input Optional, indicates this is an input filter.
 * @property {?string} input.type Optional, indicates the type of input filter. 'checkbox'
 */

/**
 * @param {InputFilter} filter
 * @param {string} selected
 * @param {function} updateFilter
 * @param {object} allSelected
 */
export function FilterCheckbox ({ filter, selected, updateFilter, allSelected }) {
  const [value, setValue] = useState(selected)
  const lastValueRef = useRef(value)
  const updateFilterRef = useRef(updateFilter)
  const filterId = filter.id

  useEffect(() => {
    updateFilterRef.current = updateFilter
  }, [updateFilter])

  const disabled = filter.getDisabled?.(allSelected) ?? false

  console.debug('Filter checkbox updating', { disabled, value, selected })

  useEffect(() => {
    if (((value ?? '') !== (selected ?? '')) || (disabled && !!value)) {
      if (lastValueRef.current === selected) {
        const nextValue = disabled ? null : value
        lastValueRef.current = nextValue
        updateFilterRef.current(filterId, nextValue)
        if (disabled && !!value) {
          setValue('')
        }
      } else {
        const nextValue = disabled ? '' : selected
        lastValueRef.current = nextValue
        if (disabled && !!selected) {
          updateFilterRef.current(filterId, null)
        }
        setValue(nextValue)
      }
    }
  }, [value, selected, disabled, filterId])

  return (
    <Tooltip
      label={(disabled ? filter.disabledDescription : null) ?? filter.description ?? 'No description'}
      disabled={!filter.description && (!disabled || !filter.disabledDescription)}
      refProp='rootRef'
    >
      <Checkbox
        label={filter.inputLabel ?? filter.label}
        checked={!!value}
        onChange={(event) => setValue(event.currentTarget.checked ? '1' : null)}
        disabled={disabled}
      />
    </Tooltip>
  )
}
