import React, { memo, useCallback, useContext, useEffect, useMemo, useState, createContext } from 'react';
import {
  useCycle,
  useCycleApplicantsDetailsList,
  useCycleDegrees,
  useCycleRegions,
  useCycleStatuses,
  useCycleTableModuleInfos
} from './CycleHooks';
import {
  NamespaceContext,
  TableQueryingContext,
  TableRowIdsContext,
  CycleContext,
  TableRowContext
} from '../../core/ReactTable/TableContexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useCycleApplicantsDetailListTable } from './DetailViewHooks';
import { useAccount, useMobile } from '../../util/Hooks';
import { Box, Center, Loader, Modal, Portal, Space, Table } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { DetailViewSelectedHandler } from './DetailViewSelectedHandler';
import { BetaCardWrapper, MobileCardLoader } from './ApplicantCard/ApplicantCard';
import { CleanTable, CleanTableContextsProvider } from '../../core/ReactTable/CleanTable';
import {
  rowsLoaded,
  selectTableActiveRequestId,
  selectTableRowIdsByActiveRequestId,
  selectTableRowProperty
} from '../../core/ReactTable/tableRowsSlice';
import { hasDockedCards, selectAllOpenedKeys, selectCard, toggleCard } from './detailViewSlice';
import { selectIsRowSelected } from '../../core/ReactTable/tableSelectedRowsSlice';
import { useHover, useWindowEvent } from '@mantine/hooks';
import { useSpringGestures } from '../../core/useSpringGestures';
import FlagsModal from './FlagsModal'
import RezviewModal from './RezviewModal'
import { Helmet } from 'react-helmet'

const SIDE_NAV_WIDTH = 240
const CARD_RIGHT_MARGIN = 32
export const CardWidthContext = createContext()

export function DetailViewTableApp () {
  const { cycleId } = useParams()
  const account = useAccount()
  const dispatch = useDispatch()
  const openedCards = useSelector(state => selectAllOpenedKeys(state))
  const statusesResult = useCycleStatuses(cycleId)
  const moduleInfosResult = useCycleTableModuleInfos(cycleId)
  const dockedCards = useSelector(state => hasDockedCards(state))
  const isMobile = useMobile()

  const scrollbarWidth = (window.innerWidth - document.body.clientWidth)
  const [isNavOpen, setIsNavOpen] = useState(() => document.getElementsByClassName('open-nav').length > 0)
  const navOffset = useMemo(() => isNavOpen ? SIDE_NAV_WIDTH : 0, [isNavOpen])
  const cardWidth = `calc(25vw - ${scrollbarWidth}px - ${CARD_RIGHT_MARGIN}px)`

  useWindowEvent('menu:open', () => {
    setIsNavOpen(true)
  })

  useWindowEvent('menu:close', () => {
    setIsNavOpen(false)
  })

  const tableWidth = useMemo(() => {
    return dockedCards ? `calc(75vw - ${navOffset}px - ${scrollbarWidth}px)` : '100%'
  }, [dockedCards, navOffset, scrollbarWidth])

  const cycleData = useMemo(() => {
    const [modules] = moduleInfosResult
    const [statuses] = statusesResult

    const _modules = modules?.items ?? []

    return {
      id: cycleId,
      statuses: statuses?.items ?? [],
      modules: _modules
    }
  }, [statusesResult, moduleInfosResult, cycleId])

  const toggleRow = useCallback((id) => {
    dispatch(toggleCard({ id }))
  }, [dispatch])

  useSpringGestures()

  return (
    <CycleContext.Provider value={cycleId}>
      {isMobile
        ? <DetailViewMobileTableLoader cycleId={cycleId} account={account} cycleData={cycleData} onRowClick={toggleRow} />
        : (
          <>
            <Box w={tableWidth} pl={32} pr={openedCards.length > 0 ? null : 32}>
              <DetailViewTableLoader cycleId={cycleId} account={account} onRowClick={toggleRow} />
            </Box>
            <Portal>
              {openedCards.length > 0 && (
                <CardWidthContext.Provider value={cardWidth}>
                  {openedCards.map(id => (
                    <span key={`applicant-card-${id}`}>
                      <BetaCardWrapper
                        applicantId={id}
                        cycleData={cycleData}
                        account={account}
                        />
                    </span>
                  ))}
                </CardWidthContext.Provider>
              )}
            </Portal>
          </>
          )}
        <FlagsModal />
        <RezviewModal />
    </CycleContext.Provider>
  )
}

export const DetailViewMobileTableLoader = memo(function DetailViewMobileTable ({ cycleId, account, cycleData }) {
  const [cardId, setCardId] = useState(null)

  console.log('DetailViewMobileTableLoader', { cycleId, account, cycleData })

  return (
    <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
    </Helmet>
    <Box w='100%' p={16}>
      <DetailViewTableLoader cycleId={cycleId} account={account} isMobile onRowClick={setCardId} />
    </Box>
    <Modal.Root fullScreen opened={!!cardId} onClose={() => setCardId(null)}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body p={0}>
            <MobileCardLoader applicantId={cardId} cycleData={cycleData} account={account} />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  )
})

const DetailViewTableLoader = memo(function DetailViewTableLoader ({ cycleId, account, isMobile = false, onRowClick: handleRowClick }) {
  const [moduleInfos, modulesRefreshing] = useCycleTableModuleInfos(cycleId)
  const [statuses, statusesRefreshing] = useCycleStatuses(cycleId)
  const [regions, regionsRefreshing] = useCycleRegions(cycleId)
  const [educationOptions, educationOptionsRefreshing] = useCycleDegrees(cycleId)
  const [cycle, cycleRefreshing] = useCycle(cycleId)
  const defaultDisplay = cycle?.default_display ?? 'percentile'

  const anyLoading = modulesRefreshing || !account.email || statusesRefreshing || regionsRefreshing || educationOptionsRefreshing || cycleRefreshing

  const statusTiers = useMemo(() => {
    if (!statuses?.items) {
      return []
    }
    const tiersMap = new Map()
    for (const status of statuses.items) {
      if (status.tier) {
        tiersMap.set(status.tier.id, status.tier)
      } else {
        console.warn('Did not find tier where expected in status.', { status, statuses })
      }
    }
    return [...tiersMap.values()]
  }, [statuses])

  return (
    <>
      {!!anyLoading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!anyLoading && (
        <DetailViewTable
          cycleId={cycleId}
          account={account}
          moduleInfos={moduleInfos}
          statuses={statuses}
          statusTiers={statusTiers}
          regions={regions}
          educationOptions={educationOptions}
          onRowClick={handleRowClick}
          isMobile={isMobile}
          defaultDisplay={defaultDisplay}
        />
      )}
    </>
  )
})

const DetailViewTable = memo(function DetailViewTable (
  {
    cycleId,
    account,
    moduleInfos,
    statuses,
    statusTiers,
    regions,
    educationOptions,
    namespace = 'cycle-detail-view',
    onRowClick: handleRowClick,
    isMobile,
    defaultDisplay
  }
) {
  const handleRowClicked = useCallback((applicantId) => {
    console.debug('Detail view table row clicked.', applicantId)
    if (!applicantId) return true
    const intApplicantId = parseInt(applicantId)
    handleRowClick(intApplicantId)
  }, [handleRowClick])

  const { columns, defaultFilters, defaultHiddenColumns, abstractOrderVariants, ...otherTableProps } = useCycleApplicantsDetailListTable(
    namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, educationOptions, defaultDisplay
  )

  return (
    <CleanTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
      abstractOrderVariants={abstractOrderVariants}
    >
      <CycleDetailTableDataProvider cycleId={cycleId}>
        <CleanTable
          {...otherTableProps}
          abstractOrderVariants={abstractOrderVariants} // TODO [long term/order variants] refactor into redux state or provider
          onRowClick={handleRowClicked}
          columns={columns}
          isMobile={isMobile}
        />
        <DetailViewSelectedHandler account={account} />
      </CycleDetailTableDataProvider>
    </CleanTableContextsProvider>
  )
})

const getStatusTierColor = (status) => {
  const NOT_RECOMMENDED = 3
  const WITHDREW = 4
  const HIRED = 2
  const DUPLICATE = 5
  const id = status.tier.id

  switch (id) {
    case HIRED:
      return 'green.1'
    case NOT_RECOMMENDED:
    case WITHDREW:
    case DUPLICATE:
      return 'gray.3'
    default:
  }
}

const hoveredStyle = {
  transition: 'background 200ms',
  cursor: 'pointer',
  filter: 'brightness(0.9)'
}

const notHoveredStyle = {
  cursor: 'pointer',
  filter: 'brightness(1)',
  transition: 'filter 100ms'
}

const CycleDetailTableRow = ({ children, rowId, ...props }) => {
  const namespace = useContext(NamespaceContext)
  const isSelected = useSelector(state => selectIsRowSelected(state, rowId))
  const applicantCard = useSelector(state => selectCard(state, rowId))
  const status = useSelector(state => selectTableRowProperty(state, `${namespace}-${rowId.toString()}`, 'status'))
  const { ref, hovered } = useHover()

  const bg = (!!applicantCard && 'yellow.0') || (isSelected && 'blue.1') || getStatusTierColor(status)

  return (
    <Table.Tr ref={ref} {...props} bg={bg} style={hovered ? hoveredStyle : notHoveredStyle}>
      {children}
    </Table.Tr>
  )
}

function CycleDetailTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const activeRequestId = useSelector(state => selectTableActiveRequestId(state, namespace))
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  const [collection, queryRunning, , requestId] = useCycleApplicantsDetailsList(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded

  useEffect(() => { // Necessary as there's no good way to hook into loading from cache.
    console.debug('Cached table request sync effect running', { requestId, activeRequestId, collection })
    if (requestId && activeRequestId && collection && (requestId !== activeRequestId) && !queryRunning) {
      console.info('CycleDetailTableDataProvider request id changed', { requestId, activeRequestId })
      dispatch(rowsLoaded({ namespace: namespace, data: collection, requestId: requestId }))
    }
  }, [requestId, activeRequestId, collection, queryRunning, namespace, dispatch])

  console.debug('Cycle detail table data provider updated.', { rowIds, collection, querying, namespace, cycleId, requestId, activeRequestId })
  return (
    <TableRowIdsContext.Provider value={rowIds}>
      <TableRowContext.Provider value={CycleDetailTableRow}>
        <TableQueryingContext.Provider value={!!(querying || ((requestId !== activeRequestId) && requestId && activeRequestId))}>
          {children}
        </TableQueryingContext.Provider>
      </TableRowContext.Provider>
    </TableRowIdsContext.Provider>
  )
}
