import { randomId } from '@mantine/hooks';

export const incompleteStatusName = 'Incomplete - Review'

export function makeIncompleteStatus (position = 1) {
  return {
    name: incompleteStatusName,
    originalName: incompleteStatusName,
    position: position,
    sequence: undefined,
    default_tier_status: false,
    final: false,
    tier: '1',
    key: randomId(),
    id: null
  }
}
