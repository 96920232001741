import { QuestionType } from '../../../generated/enums/QuestionType';

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function isNumbered (questionType) { // TODO refactor remaining usage of this file.
  switch (questionType) {
    case QuestionType.TextBlock:
    case QuestionType.SectionHeader:
      return false
    default:
      return true
  }
}

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function isScored (questionType) {
  switch (questionType) {
    case QuestionType.Interview:
    case QuestionType.Multiline:
    case QuestionType.TextBlock:
    case QuestionType.SectionHeader:
    case QuestionType.ShortAnswer:
    case QuestionType.FileUpload:
      return false
    default:
      return true
  }
}

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function isAutoAnswerable (questionType) {
  switch (questionType) {
    case QuestionType.PoorSatisfactoryExcellent:
    case QuestionType.PoorAdequateImpressive:
    case QuestionType.ExpertiseSelfRating:
    case QuestionType.TextBlock:
    case QuestionType.SectionHeader:
    case QuestionType.Multiline:
    case QuestionType.ShortAnswer:
      return true
    default:
      return false
  }
}

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function hasFixedAnswers (questionType) {
  switch (questionType) {
    case QuestionType.YesNo:
    case QuestionType.TrueFalse:
    case QuestionType.PoorSatisfactoryExcellent:
    case QuestionType.PoorAdequateImpressive:
    case QuestionType.ExpertiseSelfRating:
    case QuestionType.FillInTheBlank:
    case QuestionType.Multiline:
    case QuestionType.TextBlock:
    case QuestionType.SectionHeader:
    case QuestionType.Interview:
    case QuestionType.ShortAnswer:
      return true
    default:
      return false
  }
}

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function canChangeAnswerOrientation (questionType) {
  switch (questionType) {
    case QuestionType.RatingScale:
    case QuestionType.MultipleChoice:
    case QuestionType.ChooseAllThatApply:
      return true
    default:
      return false
  }
}

/**
 * @param {QuestionType} questionType
 * @returns {boolean}
 */
function hasAnswerTemplates (questionType) {
  switch (questionType) {
    case QuestionType.MultipleChoice:
      return true
    default:
      return false
  }
}

export { isNumbered, isScored, isAutoAnswerable, hasFixedAnswers, canChangeAnswerOrientation, hasAnswerTemplates }
