import React from 'react'
import { Button, Group, Stack, TagsInput, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconFileUpload } from '@tabler/icons-react'
import _ from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import { GoBackButton } from '../../core/GoBackButton'
import { showNotification } from '@mantine/notifications'
import HireFileInput from '../components/HireFileInput/HireFileInput'

export const FILE_EXTENSIONS = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'txt', 'jpg', 'png']
const STACK_WIDTH = { base: '90%', md: '80%', lg: '60%' }

export default function FileUpload ({ tags, uploadFn, isUploadProcessing }) {
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      file: null,
      description: '',
      tags: []
    },
    validate: {
      file: value => value !== null ? null : 'Please choose a file'
    }
  })

  const onSubmit = values => {
    uploadFn(values)
      .then(() => {
        showNotification({
          message: 'Successfully created file',
          color: 'success',
          autoClose: 3000
        })

        navigate('../')
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error creating this file',
          color: 'red',
          autoClose: 3000
        })
      })
  }

  return (
    <form onSubmit={form.onSubmit(values => onSubmit(values))}>
      <Stack w={STACK_WIDTH}>
        <Text size='md'>Data files with the following extensions are allowed: <strong>*.{_.join(FILE_EXTENSIONS, ', *.')}</strong>.</Text>
        <Text size='md'>Individual data files must be less than <strong>10 MB</strong>.</Text>
        <HireFileInput {...form.getInputProps('file')} />
        <TextInput
          aria-label='Description'
          placeholder='Description'
          {...form.getInputProps('description')}
        />
        <TagsInput
          aria-label='Tags'
          placeholder='Tags'
          data={tags}
          {...form.getInputProps('tags')}
        />
        <Group>
          <Button color='blue' leftSection={<IconFileUpload size={18}/>} type='submit' loading={isUploadProcessing}>Upload</Button>
          <GoBackButton component={Link} to='../' disabled={isUploadProcessing} />
        </Group>
      </Stack>
    </form>
  )
}
