/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  MultiSelect,
  Radio,
  Space,
  Text,
  TextInput,
  Paper,
  HoverCard,
  ActionIcon,
  Stack,
  Anchor,
  Center,
  Image,
  CloseButton,
  Avatar,
  Tooltip,
  Select
} from '@mantine/core';
import { IconVideo, IconVolume, IconPhoto, IconFileUnknown } from '@tabler/icons-react';
import AnswersList from './AnswersList';
import { QuestionStateUpdate, RandomizedPoolEvent } from './QuestionsState';
import { SectionPosition } from '../../../../js/generated/enums/SectionPosition';
import { MediaType } from '../../../../js/generated/enums/MediaType';
import { QuestionType, isOpenEnded } from '../../../../js/generated/enums/QuestionType';
import { useDebouncedValue } from '@mantine/hooks';
import { QuestionCategoriesEditor } from './QuestionCategoriesEditor';
import { QuestionContentEditor } from './QuestionContentEditor';

/**
 * @typedef {object} Competency
 * @property {int} id
 * @property {string} name
 */

/**
 * @param {Question} question
 * @param {?int} activeAnswerId
 * @param {Competency[]} competencies
 * @param {boolean} mediaPopupShowing
 * @param editor
 * @param dispatch
 * @param setMediaPopup
 */
const QuestionEditor = memo(function QuestionEditor (
  {
    question,
    activeAnswerId,
    competencies,
    mediaPopupShowing,
    editor,
    dispatch,
    setMediaPopup
  }
) {
  const [keywordsBuffer, setKeywordsBuffer] = useState(question.keywords)
  const [debouncedKeywords] = useDebouncedValue(keywordsBuffer, 600)
  const lastKnownKeywords = useRef(question.keywords)

  useEffect(() => {
    if (debouncedKeywords !== question.keywords) {
      if (lastKnownKeywords.current === question.keywords) {
        dispatch({
          type: QuestionStateUpdate.UpdateQuestion,
          questionId: question.id,
          newAttributes: { keywords: debouncedKeywords }
        })
      } else {
        setKeywordsBuffer(question.keywords)
      }
    }
  }, [debouncedKeywords, question.keywords, question.id, setKeywordsBuffer, dispatch])

  useEffect(() => {
    lastKnownKeywords.current = question.keywords
  }, [question.keywords])

  const competencyOptions = useMemo(() => {
    return competencies.map(competency => { return { value: competency.id.toString(), label: competency.name } })
  }, [competencies])
  const competencyLookup = useMemo(() => {
    const lookupMap = {}
    for (const competency of competencies) {
      lookupMap[competency.id.toString()] = competency.name
    }
    return lookupMap
  }, [competencies])
  const questionCompetencies = useMemo(() => {
    return question.competencies.map(competency => competency.id.toString())
  }, [question.competencies])
  return (
    <div>
      <Space h="md" />
      <QuestionContentEditor editor={editor} />
      <Grid>
        <Grid.Col span={7} justify='flex-start' align='flex-start'>
          <Space h='sm' />
          {!isOpenEnded(question.type) && (question.type !== QuestionType.FileUpload) &&
            <AnswersList
              questionId={question.id}
              answers={question.answers}
              questionType={question.type}
              activeAnswerId={activeAnswerId}
              dispatch={dispatch}
            />
          }
          <Grid justify='flex-start' align='flex-start'>
            <Grid.Col span={8}>
              <Checkbox label='Show comment box' checked={!!question.comment} onChange={(event) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { comment: event.currentTarget.checked ? 1 : 0 } }) }/>
              <Space h='xs' />
              {question.isScorable
                ? (
                <>
                  <Checkbox label='Score this question' checked={!!question.isScored} onChange={(event) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { isScored: event.currentTarget.checked ? 1 : 0 } }) }/>
                  <Space h='xs' />
                </>
                  )
                : null }
              <Checkbox label='Page break after' checked={!!question.pageBreak} onChange={(event) => dispatch({ type: QuestionStateUpdate.RandomizeQuestion, questionId: question.id, poolEvent: RandomizedPoolEvent.TogglePageBreak, newAttributes: { pageBreak: event.currentTarget.checked ? 1 : 0 } }) }/>
              {question.type === QuestionType.RatingScale || question.type === QuestionType.MultipleChoice
                ? (
                <div>
                  <Space h='xs' />
                  <Checkbox label='Show as dropdown' checked={!!question.isDropdown} onChange={(event) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { isDropdown: event.currentTarget.checked ? 1 : 0 } }) }/>
                </div>
                  )
                : null }
            </Grid.Col>
            <Grid.Col span={4}>
              { (question.answers.size && question.answersOrientable)
                ? (
                <div>
                  <Radio.Group
                    value={question.vertical.toString()}
                    onChange={(value) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { vertical: parseInt(value) } }) }
                    name='setAnswerVertical'
                    label='Answer orientation'
                    description='Change how answers are displayed'
                  >
                    <Group mt='xs'>
                      <Radio value='1' label='Vertically' />
                      <Radio value='0' label='Horizontally' />
                    </Group>
                  </Radio.Group>
                  <Space h='xs' />
                </div>
                  )
                : null }
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={1}>
          <Divider orientation="vertical" color='gray.3' size='md' />
        </Grid.Col>
        <Grid.Col span={4}>
          <Space h='md' />
          <MultiSelect
            data={competencyOptions}
            label="Competencies"
            placeholder="Assign competencies"
            value={questionCompetencies}
            clearButtonProps={{ 'aria-label': 'Clear selection' }}
            onChange={(value) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { competencies: value.map(item => { return { id: parseInt(item), name: (competencyLookup[item] ?? item) } }) } })}
            clearable
            searchable
          />
          <Space h='md' />
          <QuestionCategoriesEditor
            questionId={question.id}
            categories={question.categories}
            dispatch={dispatch}
          />
          <Space h='md' />
          <TextInput
            label="Keywords"
            placeholder="Memorable question tags"
            value={keywordsBuffer}
            onChange={(event) => setKeywordsBuffer(event.currentTarget.value)}
          />
          <Space h='md' />
          {question.type === QuestionType.ShortAnswer && (
          <>
            <Select
              label="Validation Type"
              description='Determines if the answer is required to be typed in a certain format'
              placeholder="Assign validation"
              searchable
              data={ValidationTypes}
              value={question.validationType}
              onChange={(value) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { validationType: value } }) }
            />
            <Space h='md' />
          </>
          )}
          <QuestionsMedia
            questionMedia={question.media}
            questionTop={question.top}
            mediaPlayOnce={question.mediaPlayOnce}
            questionId={question.id}
            dispatch={dispatch}
            mediaPopupShowing={mediaPopupShowing}
            setMediaPopup={setMediaPopup}
          />
          <Space h='md' />
          <Radio.Group
            value={question.section}
            onChange={(value) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { section: value } }) }
            name='Section'
            label='Section'
            description='Change where data is displayed'
          >
            <Group mt='xs'>
              <Radio value={SectionPosition.Left} label={SectionPosition.Left} />
              <Radio value={SectionPosition.Center} label={SectionPosition.Center} />
              <Radio value={SectionPosition.Right} label={SectionPosition.Right} />
            </Group>
          </Radio.Group>
        </Grid.Col>
      </Grid>
    </div>
  )
})

const ValidationTypes = ['Email', 'Phone Number']

export default QuestionEditor

/**
 *
 * @param {?QuestionMedia} questionMedia
 * @param {int} questionTop
 * @param mediaPlayOnce
 * @param questionId
 * @param dispatch
 * @param {boolean} mediaPopupShowing
 * @param setMediaPopup
 */
const QuestionsMedia = memo(function QuestionsMedia ({ questionMedia, questionTop, mediaPlayOnce, questionId, dispatch, mediaPopupShowing, setMediaPopup }) {
  const handleCloseClick = useCallback(() => {
    dispatch({ type: QuestionStateUpdate.MediaPopupSelect, questionId: questionId, newMedia: null })
  }, [questionId, dispatch])

  const handleMediaClick = useCallback(() => {
    setMediaPopup({ showing: true, selected: questionMedia })
  }, [questionMedia, setMediaPopup])

  const mediaIcon = useMemo(() => {
    switch (questionMedia?.type) {
      case (MediaType.Audio):
        return <IconVolume onClick={handleMediaClick} />
      case (MediaType.Video):
        return <IconVideo onClick={handleMediaClick} />
      case (MediaType.Image):
        return <IconPhoto onClick={handleMediaClick} />
      default:
        return <IconFileUnknown onClick={handleMediaClick} />
    }
  }, [handleMediaClick, questionMedia?.type])

  if (questionMedia) {
    console.debug('QuestionsMedia rendering for media:', questionMedia)
  }
  const mediaSizeProps = useMemo(() => {
    if (questionMedia?.width && questionMedia?.height) {
      return { width: questionMedia.width, height: questionMedia.height }
    }
    return {}
  }, [questionMedia])

  return (
    <div>
      {questionMedia
        ? (
        <Paper shadow='sm' radius='md' withBorder p='xl'>
          <Group position='right'>
            <CloseButton
              aria-label='Remove Media'
              title='Remove Media'
              iconSize={20}
              onClick={handleCloseClick}
            />
          </Group>
          <Center>
            <QuestionMediaDisplay
              id={questionMedia.id}
              type={questionMedia.type}
              link={questionMedia.link}
              description={questionMedia.description}
              identifier={questionMedia.identifier}
              name={questionMedia.name}
              mediaIcon={mediaIcon}
              mediaSizeProps={mediaSizeProps}
            >
              <Tooltip label='Change Media' openDelay={300} withArrow>
                {questionMedia.type === MediaType.Image
                  ? (
                  <Avatar size='xl' radius='lg' src={questionMedia.link} onClick={handleMediaClick} />
                    )
                  : (
                  <ActionIcon
                    variant='outline'
                    size='xl'
                    radius='lg'
                    aria-label='Click to change media'
                  >
                    {mediaIcon}
                  </ActionIcon>
                    )}
              </Tooltip>
            </QuestionMediaDisplay>
          </Center>
          <Space h='md' />
          <Center>
            <Radio.Group
              value={questionTop.toString()}
              onChange={(value) => dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: questionId, newAttributes: { top: parseInt(value) } }) }
              name='displayMediaLocation'
              label='Display Media'
              description='Change where media is located'
            >
              <Group mt='xs'>
                <Radio value='1' label='Above Question' />
                <Radio value='2' label='Below Question' />
              </Group>
            </Radio.Group>
          </Center>
        </Paper>
          )
        : <Button tcolor='success' disabled={mediaPopupShowing} onClick={() => setMediaPopup({ showing: true, selected: null })}>Attach Media</Button> }
    </div>
  )
})

export function getMediaIconForType (type) {
  switch (type) {
    case (MediaType.Audio):
      return <IconVolume />
    case (MediaType.Video):
      return <IconVideo />
    case (MediaType.Image):
      return <IconPhoto />
    default:
      return <IconFileUnknown />
  }
}

export const QuestionMediaDisplay = memo(function QuestionMediaDisplay ({ id, type, link, description, identifier, name, mediaIcon, mediaSizeProps, children }) {
  return (
    <HoverCard shadow='md' openDelay={100} position='bottom' withinPortal>
      <HoverCard.Target>
        {children}
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <QuestionMediaDetail
          id={id}
          type={type}
          link={link}
          description={description}
          identifier={identifier}
          name={name}
          mediaIcon={mediaIcon}
          { ...mediaSizeProps }
        />
      </HoverCard.Dropdown>
    </HoverCard>
  )
})

export const QuestionMediaDetail = memo(function QuestionMediaDetail ({ id, type, link, description, identifier, name, mediaIcon, width = 640, height = 360 }) {
  return (
    <div>
      <Group>
        <ActionIcon
          variant='outline'
          size='xl'
          radius='lg'
        >
          {mediaIcon}
        </ActionIcon>
        <Stack gap={5}>
          <Text size="sm" fw={700} style={{ lineHeight: 1 }}>
            { description || identifier }
          </Text>
          <Anchor
            href={link}
            c="dimmed"
            size="xs"
            style={{ lineHeight: 1 }}
          >
            { description ? identifier : (name || link) }
          </Anchor>
        </Stack>
      </Group>
    <Space h='md' />
    {type === MediaType.Image
      ? (
      <Image
        radius='md'
        src={link}
        width={width}
        height={height}
        maw={width}
        mah={height}
      />
        )
      : (
      <div className='flex-video widescreen vimeo'>
        <iframe
          src={link}
          width={width}
          height={height}
          frameBorder='0'
          className='video-js'
          allowFullScreen
        >
        </iframe>
      </div>
        )}
    </div>
  )
})
