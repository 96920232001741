/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Button, Checkbox, Fieldset, Grid, Group, SimpleGrid, Space, TextInput, Title } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { randomId } from '@mantine/hooks'
import { notifications, showNotification } from '@mantine/notifications'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getApplicants } from '../../../js/api/applicant_repository'
import { createStatusSet, editStatusSet, getStatusSet, getStatusTiers } from '../../../js/api/status_repository'
import OrganizationSelect from './OrganizationSelect'
import StatusEditorTable from './StatusEditorTable'
import StatusSelect from './StatusSelect'
import StatusSetUsedWarning from './StatusSetUsedWarning'
import { incompleteStatusName, makeIncompleteStatus } from './util';

export default function StatusSetEditor () {
  const { id } = useParams()
  const [tiers, setTiers] = useState([])
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      client: '-1',
      default: false,
      statuses: id ? [] : [makeIncompleteStatus()],
      deleted_statuses: []
    },
    validate: {
      name: isNotEmpty('Name is required'),
      statuses: {
        position: (value, values) => !/^[1-9][0-9]*$/.test(value) ? 'Invalid position' : _.size(_.filter(values.statuses, status => status.position === value)) <= 1 ? null : 'Position must be unique',
        sequence: value => (value === undefined || /^[1-9][0-9]*$/.test(value)) ? null : 'Invalid sequence',
        name: (value, values) => value === '' ? 'Name is required' : _.size(_.filter(values.statuses, status => status.name === value)) <= 1 ? null : 'Name must be unique'
      },
      deleted_statuses: {
        replacement: isNotEmpty('Please select a replacement')
      }
    },
    transformValues: values => ({
      ...values,
      client: values.client === '-1' ? null : values.client
    })
  })

  useEffect(() => {
    if (id !== undefined) {
      getStatusSet(id).then(statusSet => {
        form.setValues({
          name: statusSet.name,
          description: statusSet.description,
          client: statusSet.client !== undefined ? statusSet.client.id.toString() : '-1',
          default: statusSet.default_cycle_status_set,
          statuses: statusSet.statuses.map(status => ({
            key: randomId(),
            id: status.id,
            name: status.name,
            originalName: status.name,
            tier: status.tier.id.toString(),
            position: status.position,
            default_tier_status: status.default_tier_status,
            sequence: status.sequence ?? undefined,
            final: status.final
          }))
        })
      })
    }

    getStatusTiers().then(tiers => setTiers(_.map(tiers, e => ({ ...e, name: _.startCase(e.name) }))))
  }, [])

  const deleteStatus = index => {
    const status = form.values.statuses[index]

    if (status.originalName === incompleteStatusName) {
      notifications.show({
        id: 'Cannot-remove-incomplete',
        color: 'yellow',
        title: 'Cannot Remove Status',
        message: `The ${incompleteStatusName} status is required for all hiring status sets.`
      })
      return
    }

    if (status.id !== null) {
      getApplicants({ status: status.id }).then(data => {
        if (_.size(data.items) > 0) {
          form.insertListItem('deleted_statuses', {
            key: randomId(),
            id: status.id,
            name: status.name,
            replacement: null
          })
        }
      })
    }

    form.removeListItem('statuses', index)
  }

  const submit = values => {
    if (id !== undefined) {
      editStatusSet(id, values)
        .then(() => {
          showNotification({
            message: 'Successfully edited status set',
            color: 'success',
            autoClose: 3000
          })
          navigate('/build/status-sets')
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }
        })
    } else {
      createStatusSet(values)
        .then(() => {
          showNotification({
            message: 'Successfully created status set',
            color: 'success',
            autoClose: 3000
          })
          navigate('/build/status-sets')
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }
        })
    }
  }

  return (
    <form onSubmit={form.onSubmit(values => submit(values))}>
      <Grid>
        <Grid.Col span={{ lg: 6 }} offset={{ lg: 3 }}>
          {id !== undefined && <StatusSetUsedWarning id={id}/>}
        </Grid.Col>
      </Grid>
      <Space h='xs'/>
      <Grid>
        <Grid.Col span={{ lg: 4, md: 12 }}>
          <SimpleGrid cols={1} verticalSpacing='xl'>
            <TextInput label='Name' {...form.getInputProps('name')} withAsterisk/>
            <TextInput label='Description' {...form.getInputProps('description')}/>
            <Checkbox label='Use this set as default for new cycles' {...form.getInputProps('default', { type: 'checkbox' })}/>
            <OrganizationSelect value={form.values.client} onChange={(val) => form.setFieldValue('client', val)}/>
            {_.size(form.values.deleted_statuses) > 0 &&
            <Fieldset>
              <Title order={6}>You have deleted statuses that were assigned to existing applicants. Please choose what the new status for these applicants should be</Title>
              {form.values.deleted_statuses.map((status, index) => (
                <StatusSelect key={status.key} form={form} index={index}/>
              ))}
            </Fieldset>
            }
          <Group>
            <Button color='success' type='submit'>Save</Button>
            <Button color='gray' component={Link} to='/build/status-sets'>Cancel</Button>
          </Group>
          </SimpleGrid>
        </Grid.Col>
        <Grid.Col span={{ lg: 8, md: 12 }}>
          <StatusEditorTable form={form} name='statuses' tiers={tiers} deleteStatus={deleteStatus} statusSetId={id}/>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ lg: 8, md: 12 }}>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ lg: 8, md: 12 }} offset={{ lg: 4 }}>
        </Grid.Col>
      </Grid>
    </form>
  )
}
